

















































import { Vue, Component, Watch } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { Ability } from '@/types/Ability'
import { dateDuJourFoDocument, getFileNameFromHeader } from '@/utils/helpers'
import PopUpInfoIntervenant from '@/components/PopupInfoIntervenant.vue'
import ExaGenericTable from '@exatech-group/generic-table/src/GenericTable.vue'

@Component({
    components: {
        FontAwesomeIcon,
        PopUpInfoIntervenant,
        ExaGenericTable
    },
    computed: {
        ...mapGetters('auth', ['user_session_id', 'can']),
        ...mapGetters('deontologies', ['getDeontologies', 'loading', 'error', 'meta', 'links', 'totalRows', 'currentPage', 'lastPage'])
    }
})

export default class Deontologies extends Vue {
    Ability = Ability
    // DATA
    dataForTab: Array<any> = []
    filtres: Array<any> = []
    modalUserId = 0
    showModalUserInfo = false
    defaultFetchParams = {
        page: 1,
        sort: 'user.name',
        direction: 'asc'
    }

    genericfields = [ // genericfields for GenericTable
        { key: 'user.name', label: 'Examinateur', sortable: true, class: '', type: 'actionText' },
        { key: 'candidat.name', label: 'Candidat', sortable: true, class: '', type: 'text' },
        { key: 'comment', label: 'Commentaire', sortable: false, class: '', type: 'text' }
    ]

    // store getters
    getDeontologies: any
    loading: any
    meta: any

    // COMPUTED
    get totalDeontologiesFilteredText() {
        const total = this.dataForTab?.length ?? 0
        return `${total} problème${total > 1 ? 's' : ''} de déontologie au total`
    }

    // WATCHERS
    @Watch('user_session_id')
    refreshInterface () {
        this.load()
    }

    @Watch('getDeontologies')
    reloadDataForGenericTab () {
        this.setDataForGenericTab(this.getDeontologies)
    }

    // METHODS
    mounted () {
        if (this.$store.getters['auth/user_session_id'] !== null) {
            this.load()
        }
    }

    load () {
        this.$store.dispatch('deontologies/fetchDeontologies').then((res) => {
            this.setDataForGenericTab(res.data.data)
            this.setFiltersForGenericTab()
        }).catch((err) => {
            console.log('Error while fetching Deontologies', err)
        })
    }

    setDataForGenericTab(data: Array<any>) {
        if (data) {
            const dataForGenericTab = []
            for (const deontologie of data) {
                const line = []
                line.push({
                    label: '',
                    item: deontologie.user?.id,
                    text: `${deontologie.user?.name} ${deontologie.user?.first_name}`,
                    type: 'actionText',
                    typeAction: 'infoUser',
                    class: 'text-info item_action'
                })
                line.push({
                    label: '',
                    item: `${deontologie.candidat?.name} ${deontologie.candidat?.first_name}`,
                    type: 'text',
                    typeAction: null,
                    class: ''
                })
                line.push({
                    label: '',
                    item: deontologie.commentaire,
                    type: 'text',
                    typeAction: null,
                    class: '',
                    class_td: 'w-50'
                })
                dataForGenericTab.push(line)
            }
            this.dataForTab = dataForGenericTab
        }
    }

    setFiltersForGenericTab() {
        const filters = []
        filters.push({
            libelle: 'Nom',
            defautOptionlibelle: 'Rechercher un',
            model: 'user.name',
            value: '',
            data: '',
            loading: this.loading,
            options: {
                type: 'form',
                fieldsKey: 'user.name'
            }
        })
        filters.push({
            libelle: 'Nom',
            defautOptionlibelle: 'Rechercher un',
            model: 'candidat.name',
            value: '',
            data: '',
            loading: this.loading,
            options: {
                type: 'form',
                fieldsKey: 'candidat.name'
            }
        })
        this.filtres = filters
    }

    handleTableEvent (paParams: Array<any>): void {
        if (paParams && paParams[0] && paParams[1]) {
            switch (paParams[0]) {
                case 'filterHandler':
                    this.filtreSortHandler(paParams[1])
                    break
                case 'sortHandler':
                    this.filtreSortHandler(paParams[1])
                    break
                case 'onLoadPage':
                    // load more deontologies
                    this.$store.dispatch('suppleant/getMoreSuppleant', paParams[1])
                    break
                case 'infoUser':
                    // open modal
                    this.modalUserId = paParams[1]
                    this.showModalUserInfo = true
                    break
                default:
                    break
            }
        }
    }

    filtreSortHandler (params: Record<string, string | number>) {
        if (JSON.stringify(this.defaultFetchParams) !== JSON.stringify(params)) {
            this.defaultFetchParams = JSON.parse(JSON.stringify(params))
            this.$store.dispatch('deontologies/fetchDeontologies', params).then((res) => {
                this.setDataForGenericTab(res.data.data)
            })
        }
    }

    exportDeontologies () {
        let fileName = 'export_problemes_deontologie'
        fileName += '_' + dateDuJourFoDocument() + '.xlsx'

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Exportation en cours de création ...', infosToaster)

        this.$store.dispatch('deontologies/exportDeontologies')
            .then((response) => {
                const fileNameTemp = getFileNameFromHeader(response.headers)
                if (fileNameTemp) {
                    fileName = fileNameTemp
                }
                const url = URL.createObjectURL(new Blob([response.data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('Download', fileName)
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)

                // affichage toaster succès
                const idSuccess = 't_success_' + Math.random()
                infosToaster.id = idSuccess
                infosToaster.variant = 'success'
                this.$bvToast.toast('Export terminé', infosToaster)
                setTimeout(() => {
                    this.$bvToast.hide(idSuccess)
                }, 3000)
            })
            .catch((error) => {
                console.log('Erreur exportation problèmes déontologie : ', error)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }
}
